<template>
  <div>
    {{ scope.row.scale.parent.name }}
  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import requestSender from "@/api/base/requestSender";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-scale-pay-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  data() {
    return {}
  },

  methods: {}
}

</script>

<style scoped lang="scss">

</style>
